import Qs from 'qs';
import Cookies from 'js-cookie';
import {
  deleteUrlParam,
  getUrlParam,
  showJoinKidsAcademyModal,
  storage,
  downloadWorksheetHandler
} from '../utils';
import {
  GET_PARAMS,
  LOCAL_STORAGE_KEYS,
  MODALS,
  WEBSITE_CUSTOM_EVENTS,
  WORKSHEET_ACTION_TYPES,
  // MOBILE_WIDTH
} from '../config';
import toastr from 'toastr/toastr';


const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
if (isIE11) $('body').addClass('_ie11');

const showAdsBannerModal = () => {
  const adsBannerModal = $('[data-selector="ads-banner-modal"]:not(.inline-monetization)');
  const adsBannerModalClose = $('[data-selector="ads-banner-modal-close"]');

  if(!adsBannerModal) return;

  adsBannerModal.modal('show');

  $(adsBannerModalClose).on('click', () => { adsBannerModal.modal('hide'); });
};

const reloadHtmxAdsBanners = () => {
  const ads = document.querySelectorAll('[hx-get]');
  ads.forEach((item) => { htmx.process(item); });
};

document.addEventListener('adsBannerModalLoaded', showAdsBannerModal);
document.addEventListener(WEBSITE_CUSTOM_EVENTS.RELOAD_HTMX_ADS_BANNERS, reloadHtmxAdsBanners);

$(document).ready(function () {
  // const startWorksheetDownload = () => {
  //   // start download process
  //   const pdfLink = $(MODALS.FREE_SIGN_UP_FOR_DOWNLOAD_W_DATA).attr('data-href');
  //   function downloadURI(uri, name) {
  //     const link = document.createElement("a");
  //     link.download = name; // <- name instead of 'name'
  //     link.href = uri;
  //     link.click();
  //     link.remove();
  //   }
  //
  //   downloadURI(pdfLink, 'worksheet.pdf');
  // };

  const data = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_TO_DOWNLOAD_DATA, true);
  if (data) {
    const worksheetId = data.split('_')[0];
    const selector = data.split('_')[1];

    const $worksheetItem = worksheetId && selector ? $(`[data-selector="${selector}"][data-id="${worksheetId}"]`) : null;

    if ($worksheetItem) {
      setTimeout(() => {
        downloadWorksheetHandler(null, WORKSHEET_ACTION_TYPES.DOWNLOAD, $worksheetItem);
      }, 1);
    }
  }

  // ui notification settings
  toastr.options = {
    'closeButton': true,
    'debug': false,
    'newestOnTop': true,
    'progressBar': true,
    'positionClass': 'toast-top-right',
    'preventDuplicates': false,
    'onclick': null,
    'showDuration': '300',
    'hideDuration': '10000',
    'timeOut': '5000',
    'extendedTimeOut': '7000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut'
  };

  if (window.parentSubscriptionPastDueUntil) {
    const showModalDelay = 2000;

    setTimeout(function () {
      $('#updatePaymentMethodModal').modal('show');
    }, showModalDelay);
  }

  // show ads free modal by url get param
  const adFreeGetParamValue = getUrlParam(GET_PARAMS.AD_FREE_MODAL);
  if (adFreeGetParamValue === 'true') $(MODALS.WEBSITE_PLANS_MODAL).modal('show');

  $('#logInModal').on('hidden.bs.modal', function () {
    deleteUrlParam(GET_PARAMS.MAKE_REDIRECT);
  });

  const signUpPageUrl = '/sign-up/';
  const parentSignUpUrl = '/sign-up/?type=parent';
  const teacherSignUpUrl = '/sign-up/?type=teacher';

  const parentSignUpModal = 'signUpParentModal';
  const teacherSignUpModal = 'signUpTeacherModal';
  const signUpModal = 'signUpModal';

  const parentSignUpModalHash = '#signUpModal';
  const teacherSignUpModalHash = '#signUpTeacherModal';

  /**
   * Do some redirects to make old links works after remove sign up modal and add sign up page
   */
  function doRedirectsOnPageLoad() {
    if (window.location.pathname === signUpPageUrl) return;

    if (window.role === 'teacher') {
      window.location = teacherSignUpUrl;
      return;
    }

    if (typeof Qs === 'undefined') return;
    if (!Qs) return;
    if (typeof Qs.parse !== 'function') return;

    const queryObject = Qs.parse(location.search.substr(1));

    if (queryObject.email && queryObject.email.length
      && queryObject.modal === parentSignUpModal) {
      window.location = parentSignUpUrl + '&email=' + queryObject.email + '&step=2';
      return;
    }

    switch(queryObject.modal) {
      case parentSignUpModal:
        window.location = parentSignUpUrl;
        break;

      case teacherSignUpModal:
        window.location = teacherSignUpUrl;
        break;

      case signUpModal:
        window.location = parentSignUpUrl;
        break;
    }
  }

  /**
   *  Handle change url hash to make old links works after remove sign up modal and add sign up page
   */
  $(window).on('hashchange', function () {
    switch (window.location.hash) {
      case parentSignUpModalHash:
        window.location = parentSignUpUrl;
        break;

      case teacherSignUpModalHash:
        window.location = teacherSignUpUrl;
        break;
    }
  });

  $.ajaxSetup({
    cache: false,
    beforeSend: function (xhr) {
      const csrfToken = Cookies.get('csrftoken');
      xhr.setRequestHeader('X-CSRFToken', csrfToken);
    }
  });

  navigator.sayswho = (function () {
    // TODO move to utils
    const N = navigator.appName, ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
    if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
    M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
    return M;
  })();
  if (navigator.sayswho[0] == 'Firefox' && parseFloat(navigator.sayswho[1]) < 5) {
    const pos = $('.menu .menu-blue').offset();
    $('ul.menu li ul.dropdown-menu').css('top', '155px');
    $('ul.menu li ul.dropdown-menu').css('left', (pos.left - 11) + 'px');
  }

  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  if (width < 350) {
    $('.menu-blue').find('.js-title').text('WORKSHEETS');
  } else {
    $('.menu-blue').find('.js-title').text('FREE WORKSHEETS');
  }

  /**
   * For IE 6-9
   * set banner image url for simple img tag, shows banner image
   */
  const handleMainBannerImageLoad = function () {
    const browserName = navigator.sayswho[0];
    const browserVersion = navigator.sayswho[1];

    if (browserName === 'MSIE' && browserVersion === '6.0'
      || browserName === 'MSIE' && browserVersion === '7.0'
      || browserName === 'MSIE' && browserVersion === '9.0'
    ) {
      const $mainBanner = $('[data-selector*="website-banner"]');
      const $mainBannerImg = $mainBanner.find('[data-selector*="banner-img-element"]');
      const mainBannerImgUrl = $mainBannerImg.first().attr('src');
      const $pictureElement = $mainBanner.find('[data-selector*="banner-picture-elem"]');
      const $imgElementForIE = $mainBanner.find('[data-selector*="banner-ie-image"]');

      $pictureElement.hide();
      $imgElementForIE.attr('src', mainBannerImgUrl);
      $imgElementForIE.show();
    }
  };


  doRedirectsOnPageLoad();
  handleMainBannerImageLoad();
});


window.selectParentTab = function () {
  const openParentSignUpUrl = '/sign-up/?accountType=0';
  window.location = openParentSignUpUrl;
};

window.selectTeacherTab = function () {
  const openTeacherSignUpUrl = '/sign-up/?accountType=1';
  window.location = openTeacherSignUpUrl;
};


/***
 *  polyfill of `Array.prototype.find` for IE 11
 */
if (!Array.prototype.find) {
  Array.prototype.find = function(predicate) {
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    const list = Object(this);
    const length = list.length >>> 0;
    const thisArg = arguments[1];
    let value;

    for (let i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}

/***
 *  polyfill of `Array.prototype.findIndex` for IE 11
 */
Array.prototype.findIndex = Array.prototype.findIndex || function(callback) {
  if (this === null) {
    throw new TypeError('Array.prototype.findIndex called on null or undefined');
  } else if (typeof callback !== 'function') {
    throw new TypeError('callback must be a function');
  }
  const list = Object(this);
  // Makes sures is always has an positive integer as length.
  const length = list.length >>> 0;
  const thisArg = arguments[1];
  for (let i = 0; i < length; i++) {
    if ( callback.call(thisArg, list[i], i, list) ) {
      return i;
    }
  }
  return -1;
};

/**
 * Hide bottom adsense
 */
$(document).on('click', '[data-selector="bottom-adsense-close"]', () => {
  const $bottomAdsense = $('[data-selector="bottom-adsense"]');
  const $footer = $('[data-selector="footer"]');
  $bottomAdsense.hide();
  $footer.removeClass('_has-adsense');
});


// document.addEventListener('DOMContentLoaded', function (_) {
  // const DESKTOP = 'desktop';
  // const MOBILE = 'mobile';
  // const adsenseTypeAttr = 'data-adsense-type';
  //
  // const desktopAdsense = `[${adsenseTypeAttr}="${DESKTOP}"]`;
  // const mobileAdsense = `[${adsenseTypeAttr}="${MOBILE}"]`;
  // const $desktopAdsense = $(desktopAdsense);
  // const $mobileAdsense = $(mobileAdsense);
  //
  // if (window.innerWidth <= MOBILE_WIDTH) {
  //   // mobile
  //   $desktopAdsense.each((_, elem) => {
  //     elem.parentElement && elem.parentElement.removeChild(elem);
  //   });
  // } else {
  //   // desktop
  //   $mobileAdsense.each((_, elem) => {
  //     elem.parentElement && elem.parentElement.removeChild(elem);
  //   });
  // }
// });

if ($('[data-url="ad-free-subscription"]').length) {
  ga('send', 'event', 'Add_free_subscriptions', 'Show', 'Load');

  const $plansModal = $(MODALS.WEBSITE_PLANS_MODAL);
  $plansModal.find('.close.custom-modal__close').hide();


  $plansModal.modal({backdrop: 'static', keyboard: false});
  $plansModal.modal('show');
}


(function () {
/**
 * add/remove mobile filter grey overlay on learning resources pages
 */
const mobileFilterOverlay = '[data-selector="mobile-filter-overlay"]';
const filterCloseBtn = '#filter-inner-close';
const filterOpenBtn = '#filters-mob-open';

$(document)
  .on('click', mobileFilterOverlay, () => {
    $(filterCloseBtn).trigger('click');
    $(mobileFilterOverlay).fadeOut();
  })
  .on('click', filterCloseBtn, () => {
    $(mobileFilterOverlay).fadeOut();
  })
  .on('click', filterOpenBtn, () => {
    const overlay = `<span style="position: fixed;
                                  z-index: 2;
                                  background: rgb(0 0 0 / 35%);
                                  top: 0;
                                  right: 0;
                                  left: 0;
                                  bottom: 0;"
                                  data-selector="mobile-filter-overlay"
                                  ></span>`;
    $('body').append(overlay);
  });
}());


const homeJoinKidsAcademyModalButton = '[data-selector="show-join-kids-academy-modal"]';
$(document).on('click', homeJoinKidsAcademyModalButton, (e) => {
  e.preventDefault();
  showJoinKidsAcademyModal({ checkStorage: false });
});
